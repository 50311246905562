:root {
  --button-bg: #d28913;
  --button-active-bg: #9b601d;
  --small-button-bg: #919191;
  --small-button-active-bg: #646464;
  --button-text: #ffffff;
  --card: #ffffff;
  --accountText: #ffffff;
  --statusText: #333333;
  --bg-gradient_1: rgb(250, 250, 110);
  --bg-gradient_2: rgb(230, 186, 16);
  --gradient_1: rgb(243, 208, 83);
  --gradient_2: rgb(209, 160, 27);
  --gradient_3: rgb(168, 133, 9);
  --success: #24a13f;
  --warning: #ca5824;
  --error: #ca2f24;
}

* {
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 14px;
  font-weight: bold;
}

html,
body {
  padding: 0;
  margin: 0;
  background: linear-gradient(
      var(--bg-gradient_1) 0%,
      var(--bg-gradient_2) 100%
    )
    fixed;
}

.colorGradient {
  background: linear-gradient(
      to bottom,
      var(--gradient_1) 0%,
      var(--gradient_2) 65%,
      var(--gradient_3) 100%
    )
    fixed;
}

.page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card {
  background-color: var(--card);
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 300px;
  transition: 0.3s;
  margin: 50px;
  position: relative;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card_header {
  height: 250px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
}

.card_header_image {
  position: absolute;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card_body {
  padding: 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_body_view_contract {
  padding-right: 15px;
  padding-left: 15px;
}

.card_footer {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spin {
  animation: item-spin infinite 20s linear;
}

.statusText {
  color: var(--statusText);
  font-weight: normal;
  text-align: center;
}

.accountText {
  color: var(--accountText);
  font-weight: bold;
}

.ns {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

._90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(-90deg);
}

.button {
  display: inline-block;
  padding: 7px 14px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: var(--button-text);
  background-color: var(--button-bg);
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
}

button:disabled {
  opacity: 0.5;
}

.button:active {
  background-color: var(--button-active-bg);
}

.small_button {
  display: inline-block;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: var(--button-text);
  background-color: var(--small-button-bg);
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
}

.small_button:disabled {
  opacity: 0.5;
}

.small_button:active {
  background-color: var(--small-button-active-bg);
}

@keyframes item-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #fff;
  text-decoration: none;
  padding: 3px 10px;
  line-height: 18px;
  background-color: darkgoldenrod;
  border-radius: 5px;
}
a:hover {
}
